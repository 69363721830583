import { createStore } from "vuex";

export default createStore({
  state: {
    token: "",
  },
  getters: {},
  mutations: {
    setNumber(state) {
        state.number = 5;
    },
    setNumberIsWhat(state, number) { // 增加一个带参数的mutations方法
        state.number = number;
    },
  },
  actions: {},
  modules: {},
});
