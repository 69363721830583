// import http from "@/common/http.js";
// import bridge from "@/common/bridge.js";
// import jweixin from "@/common/jweixin-1.6.0.js";
// import store from "@/store";

const getDate = function (value) {
  var now = new Date(value),
    y = now.getFullYear(),
    m = now.getMonth() + 1,
    d = now.getDate()
  return (
    y +
    '-' +
    (m < 10 ? '0' + m : m) +
    '-' +
    (d < 10 ? '0' + d : d) +
    ' ' +
    now.toTimeString().substr(0, 8)
  )
}

// 生成随机id
const generateRandomId = () => {
  let S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return (
    S4() +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    S4() +
    S4()
  )
}

export default {
  getDate,
  generateRandomId
}
